import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./styles.scss";
import {
  arrayTes,
  peoplePopup,
  LTCpackage,
  YTLinkResult,
  Partnership
} from "./const";
import { Box, Modal } from "@mui/material";
import RegisterForm from "share/registerForm";
import bgForm from "assets/images/home/bg-form.jpg";
import { Link } from "react-router-dom";
import FormCountDownt from "app/components/formCountDown";
import PopupUserRegister from "app/components/popupUserRegister";
import CountUp from "./components/countUp";
import FamilyHappyImg from "assets/images/family.jpg";
gsap.registerPlugin(ScrollTrigger);
function ClientModulesHome() {
  const [type, setType] = useState("lrworkshop");
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});
  const storedParams = localStorage.getItem("utm");
  const parsedParams = JSON.parse(storedParams);
  const [openModal, setOpenModal] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [registerCount, setRegisterCount] = useState(64231);

  useEffect(() => {
    const stickyElement = document.querySelector(".ltc-header");
    const stickyOffset = stickyElement.offsetTop;
    const handleScroll = () => {
      if (window.scrollY >= stickyOffset) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };


    const intervalId = setInterval(setRegisterCount(prevCount => prevCount + 1), 10000);
    window.addEventListener("scroll", handleScroll);
    // Cleanup the event listener on component unmount
    return () => {
      clearInterval(intervalId);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const renderPopUp = () => {
    const style = {
      top: "15%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
      // boxShadow: 24,
      position: "absolute",
      display: "block",
      width: isMobile ? "90%" : "50%",
    };
    return (
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setStep(0);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          borderRadius: "40px",
        }}
      >
        <Box sx={style}>
          <div
            className="container "
            style={{ overflow: "auto", maxHeight: "60vh" }}
          >
            <div className="position-absolute" style={{ zIndex: 1 }}>
              {renderSwitch()}
            </div>
          </div>
        </Box>
      </Modal>
    );
  };
  const renderSwitch = () => {
    switch (step) {
      case 0:
        return (
          <RegisterForm
            title={"Đăng ký nhận ưu đãi"}
            type={type}
            setStep={(i) => {
              setStep(i);
            }}
            setData={(i) => {
              setData(i);
            }}
            parsedParams={parsedParams}
          />
        );
      case 1:
        return (
          <div className="row popup">
            <div className="popup-background"></div>
            <div className="overlay" />
            <div className="col-12 position-relative">
              <div className="px-5 py-5">
                <div className="row">
                  <h2>CÁM ƠN BẠN </h2>
                  <h4 style={{ textAlign: "center" }}>
                    ĐÃ ĐĂNG KÝ CHƯƠNG TRÌNH
                  </h4>
                </div>
                <div className="row mt-4 text-center">
                  <p> Chúng tôi sẽ sớm liên hệ lại với bạn</p>
                  <p>Liên hệ hotline để được giải đáp thắc mắc</p>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return <></>;
    }
  };
  const renderHeader = () => {
    const CONTENT_MENU = [
      {
        title: "Dành Riêng Cho Phụ Huynh",
        url: "#ltc-parent",
      },

      {
        title: "Thành Quả",
        url: "#ltc-testimonial",
      },
      {
        title: "Lộ Trình Đồng Hành",
        url: "#ltc-roadmap",
      },
      {
        title: "Đối Tác",
        url: "#ltc-partnership",
      },
    ];
    const renderDropdownChildren = (data) => {
      return (
        <ul>
          {data?.map((child, index) => {
            return (
              <li key={index}>
                <Link to={child.url}>{child?.title?.toLocaleUpperCase()}</Link>
                {child.childMenu && renderDropdownChildren(child.childMenu)}
              </li>
            );
          })}
        </ul>
      );
    };
    return (
      <div className={`ltc-header  ${isSticky ? "fixed" : ""}`}>
        <div className="ltc-header-menu">
          <div className="container">
            <ul className="header-dropdown ">
              {CONTENT_MENU?.map((ele, index) => {
                return (
                  <li key={index}>
                    <a href={ele.url}>{ele.title.toLocaleUpperCase()}</a>

                    {ele.childMenu && renderDropdownChildren(ele.childMenu)}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

      </div>
    );
  };
  const renderParentSection = () => {
    return (
      <div className="ltc-parent"
        // style={{
        //   backgroundImage: `url(${FamilyHappyImg})`,
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        // }} 
        id="ltc-parent">
        <div className="headline-h1 pt-10">DÀNH RIÊNG CHO PHỤ HUYNH</div>
        <div className="row banner-row">
          <div className="col-md-6 col-left-iframe py-10">
            <div>
              <div className="content-top-iframe">
                <iframe
                  src="https://www.youtube.com/embed/dQw4w9WgXcQ?si=x0HLVSq-9Yz8Mgvv"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen>
                </iframe>
                <div className="headline-h4 pt-3 ">Video Của Giảng Viên</div>
              </div>
              <div className="content-bottom-card-info">
                <div className="card-info-btm">
                  <div className="teacher-info">Thông Tin Giảng Viên</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 justify-content-center align-items-center py-10 px-10 d-flex">
            <RegisterForm
              title={"Đăng ký"}
              type={type}
              setStep={(i) => {
                setStep(i);
              }}
              setData={(i) => {
                setData(i);
              }}
              parsedParams={parsedParams}
            />
          </div>
        </div>
      </div >
    );
  };

  const renderTestimonial = () => {
    return (
      <div className="ltc-testimonial"
      // style={{
      //   backgroundImage: `url(${FamilyHappyImg})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      // }} id="ltc-testimonial"
      >
        <div className="container">
          <div className="headline-h1 pt-10">THÀNH QUẢ</div>
          <div className="row">
            {arrayTes.map((tes, index) => {
              return (
                <div className="col-md-4 mt-20" key={index}>
                  <div className="testi-name">
                    <p>
                      {tes.name}
                    </p>
                  </div>
                  <div className="testi-dialouge-iframe">
                    <iframe
                      src={tes.src}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen>
                    </iframe>
                  </div>

                  <div className="testi-text-box">
                    <p>
                      {tes.text}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  const renderRoadMap = () => {
    const renderHeaderRoadmap = (title) => {
      return <div className="roadmap-header-1 pt-10">
        {title}
      </div>
    }
    return (
      <div className="ltc-roadmap"
        // style={{
        //   backgroundImage: `url(${FamilyHappyImg})`,
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        // }} 
        id="ltc-roadmap">
        <div className="container">
          <div className="headline-h1 pb-5 pt-5">
            LỘ TRÌNH ĐỒNG HÀNH
          </div>
          <div className="row">
            <div className="col-md-4 pt-10">
              <div className="roadmap-con-1">
                {renderHeaderRoadmap("Nguyên Nhân")}
                <div className="py-10">
                  <p>
                    Hãy cùng tìm hiểu nguyên nhân bằng một bài test
                  </p>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="cool-button">Test Ngay</button>
                </div>
              </div>
            </div>
            <div className="col-md-4 pt-10">
              {renderHeaderRoadmap("Phương Pháp Can Thiệp")}

              <div className="my-10">
                <p>
                  Phương pháp can thiệp dành riêng cho đối tượng
                </p>
              </div>
              <div className="d-flex justify-content-evenly">
                <button className="cool-button">Phụ Huynh</button>
                <button className="cool-button">Bé</button>
              </div>
            </div>
            <div className="col-md-4 mt-10">
              {renderHeaderRoadmap("LTC Hỗ Trợ")}
              <div className="col">
                {LTCpackage.map((pack, index) => {
                  return (
                    <div className="row package-row" key={index}>
                      <div className="col-3">
                        {pack.name}
                      </div>
                      <div className="col">
                        {pack.benefit?.map((temp, index2) => {
                          return (
                            <div className="row" key={index2}>
                              {temp}
                            </div>
                          )
                        })
                        }
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderResult = () => {
    const renderHeaderResult = (title) => {
      return <div className="headline-h1 pt-10">
        {title}
      </div>
    }

    return (
      <div className="ltc-result pb-10 px-0"
      // style={{
      //   backgroundImage: `url(${FamilyHappyImg})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      // }}
      >
        <div className="container">
          {renderHeaderResult("HIỆU QUẢ")}
          <div className="d-flex justify-content-center">
            <div className="roadmap-header-1 pt-10">
              Sau 1 tháng đồng hành, đã có hơn {<CountUp number={64231} />} người đăng ký
            </div>

          </div>
          <div className="row">
            {YTLinkResult.map((tes, index) => {
              return (
                <div className="col-md-4 mt-10" key={index}>
                  <div className="testi-dialouge-iframe">
                    <iframe
                      src={tes.src}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen>
                    </iframe>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderPartnership = () => {
    return (
      <div className="ltc-partnership  py-15"
        // style={{
        //   backgroundImage: `url(${FamilyHappyImg})`,
        //   backgroundSize: 'cover',
        //   backgroundPosition: 'center',
        // }} 
        id="ltc-partnership">
        <div className="container">
          <div className="headline-h1">CÁC ĐỐI TÁC
          </div>
          <div className="row">
            {Partnership.map((part, index) => {
              return (
                <div className="col-md-4 mt-20" key={index}>
                  <div className="testi-name">
                    <p>
                      {part.name}
                    </p>
                  </div>
                  <div className="container">
                    <a href={part.redirect} target="_blank" rel="noreferrer">
                      <img src={part.scrImg} alt="Clickable" />
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const renderBottomPage = () => {
    return (
      <div className="ltc-form">
        <div
          className="parallax-banner section-background"
          style={{
            backgroundImage: "url(" + bgForm + ")",
            backgroundSize: "cover",
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>
                Đăng ký ngay bây giờ để nhận được ưu đãi đặc biệt
              </h1>

              <div className="container bottom-con mt-20">
                <iframe
                  src="https://www.youtube.com/embed/sAxQVIs7eNc"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen>
                </iframe>
              </div>
            </div>
            <div className="col-md-6">
              <RegisterForm
                title={"Đăng ký nhận ưu đãi"}
                type={type}
                setStep={(i) => {
                  setStep(i);
                }}
                setData={(i) => {
                  setData(i);
                }}
                parsedParams={parsedParams}
              />
              <FormCountDownt date={"09/30/2024"} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="position-relative ltc">
      <div className="ltc-banner">
        <div className="row banner-row">
          <div className="col-md-5 col-left px-0">
            <div className="content-top px-0"
              style={{
                backgroundImage: `url(${FamilyHappyImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                height: '100%',
              }}>
              <div className="headline-h2 text-center">
                HƯỚNG ĐẾN TƯƠNG LAI <br />
                CÙNG CON PHÁT TRIỂN<br />
                TOÀN DIỆN
              </div>
            </div>
            <div className="content-bottom">
              <div className="col small-left"
                style={{
                  backgroundImage: `url(${FamilyHappyImg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}>

              </div>
              <div className="col small-right" style={{
                backgroundImage: `url(${FamilyHappyImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}>
              </div>
            </div>
          </div>
          <div className="col-md-7 col-right px-0" style={{
            backgroundImage: `url(${FamilyHappyImg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}>
            <div className="button-container">
              <button className="cool-button">Học thử</button>
            </div>
          </div>
        </div>
      </div>
      {renderHeader()}
      <PopupUserRegister peoplePopup={peoplePopup} />
      {renderParentSection()}
      {renderTestimonial()}
      {renderRoadMap()}
      {renderResult()}
      {renderPartnership()}
      {renderBottomPage()}
      {renderPopUp()}
    </div >

  );
}

export default ClientModulesHome;
