export const VimeoLinkFree = [
    {
        id: "Bài 1",
        // src: "https://vimeo.com/983795110",
        src: 983795110,
        title: "Sống có ý thức"
    },
    {
        id: "Bài 2",
        // src: "https://vimeo.com/878888877",
        src: 878888877,
        title: "IMCPC",
    },
    {
        id: "Bài 3",
        // src: "https://vimeo.com/968563954",
        src: 968563954,
        title: "Thành Tích Cao",
    }
]