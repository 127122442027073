import { useEffect, useState } from "react";


function CountUp(props) {
    const [num, setNum] = useState(props.number)

    useEffect(() => {

        const intervalId = setInterval(() => { setNum(prevCount => prevCount + 1) }, 10000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return num
}

export default CountUp