import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { VimeoLinkFree } from "./const";
import "./styles.scss";
import Vimeo from "@u-wave/react-vimeo";
function Lesson(props) {
    const [watched, setWatched] = useState(false);
    return (
        <div>
            <div className="jumbotron text-center bg-light">
                <h1 className="display-4">Xin chào các bậc Phụ Huynh</h1>
                <p className="lead">Chúc mừng các anh/chị đã đủ điều kiện được trải nghiệm 3 video khóa học dưới đây miễn phí</p>
                <hr className="my-4" />
                <div className="row  no-margin-containe">
                    {VimeoLinkFree?.map((element, index) => {
                        return (
                            <div className="col-md-4 mx-0" key={(index + 1)}>
                                <div className="container">
                                    <h2>{element.id}</h2>
                                    <p>{element.title}</p>
                                    <Vimeo
                                        key={index}
                                        video={element.src}
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        title="Mối quan hệ hoàn hảo"
                                        frameBorder="0"
                                        onEnd={(e) => {
                                            console.log('Video ended: ', e)
                                        }
                                        }
                                        onPlay={() => console.log("onPlay")}
                                        onPlaying={() => console.log("onPlaying")}
                                        onPause={() => console.log("onPause")}
                                        onSeeked={() => {
                                            console.log("onSeeked");
                                        }}
                                        className="box p-5"
                                        onTimeUpdate={(e) => {
                                            // console.log("onTimeUpdate", e.seconds, video.testAt);
                                            // e.seconds >= video.testAt && setShowModal(true);
                                        }}
                                        responsive
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Lesson;